// Vars
// Colors
$black: #222;
$white: #fff;
$grey: #dbdbdb;
$lightgrey:#efefef;
$darkgrey: #333;
$orange: #f7eada;
$lightorange: #fef1cc;
$blue: #16c1f1;
$purple: #7f01ff;
$pink: #ae0182;
$primary: #dd0004;
$secondary: #862422;

// Google fonts
@font-face {
    font-family: 'Open Sans';
    src: url("../fonts/opensans-regular.woff2") format("woff2"), url("../fonts/opensans-regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Open Sans';
    src: url("../fonts/opensans-bold.woff2") format("woff2"), url("../fonts/opensans-bold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

html {
    font-size: 100%;
    line-height: 1.4;
    font-family: 'Open Sans';
	height: 100%;
}

body {
	min-height: 100%;
}

// password protect (login page)
body.pwp {
	background-image: url("img/upload_site_bg.jpg");
	background-size: cover;
	background-position: center;

	* {
		padding: 0;
		margin: 0;
	}

	main {
		color: $black;
		position: absolute;
		text-align: center;
		max-width: 400px;
		margin: 0 auto;
		background-color: white;
		padding: 2rem;
		border-radius: 20px;
		top: 50%;
		transform: translateY(-50%);
		position: absolute;
		margin-left: auto;
		margin-right: auto;
		left: 0;
		right: 0;
		text-align: center;
		box-shadow: 0 7px 16px rgba($color: #000000, $alpha: .1);

		form {
			.pwp-error {
				margin-top: 1rem;
				color: $primary;

				span {
					font-size: .9em;
					display: inline-block;
					margin-top: .5em;
					color: $darkgrey;
				}
			}

			input {
				margin-top: 1rem;

				&:first-of-type {
					width: 100%;
					background-color: $grey;
					padding: .95em .75em;
					border: 0;
					font-size: 1rem;
				}

				&[type=submit] {
					width: 100%;
					background-color: $primary;
					color: white;
					padding: .95em .75em;
					border: 0;
					margin-top: 0;
					font-size: 1rem;
					font-weight: bold;
					cursor: pointer;
				}
			}
		}
	}
}

.uploader {
	position: relative;
	background-image: url("img/upload_site_bg.jpg");
	background-size: cover;
	background-position: center;
	padding-top: 50px;
	padding-bottom: 50px;

	main {
		h1,
		.lead {
			color: white;
		}

		.dateiliste {
			> div {
				min-height: 300px;
			}
		}

		.feedback {
			max-height: 350px;
		}
	}

	footer {
		color: white;
		
		a {
			color: white;
		}
	}
}